<template>
  <main>
    <article class="order">
      <section id="index_main" class="main-section withheader darkbg">
        <Iheader></Iheader>
        <div class="wrapper">
          <section class="order-outer">
            <div class="order-body">
              <!-- You send 相关信息 -->

              <!-- --------- -->

              <div class="order-wrap-shadow">
                <!-- 订单状态 状态步骤条  -->
                <section class="order-timeline clrfix" id="section_timeline">
                  <div class="timeline-container">
                    <ol
                      id="timeline_steps"
                      class="clrfix"
                      :class="{
                        expired: socketData.status == 4,
                      }"
                      :data-value="socketData.to.icon_name"
                    >
                      <!-- Awaiting deposit -->

                      <li
                        id="timeline_new"
                        :class="{
                          active: socketData.status == 0,
                        }"
                      >
                        <div>
                          <!-- <span class="ico deposit"></span> -->
                          <!-- <img src="/img/time1.svg" class="ico" alt=""> -->
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M38 13C38 8.02944 33.9706 4 29 4C24.0294 4 20 8.02944 20 13C20 17.9706 24.0294 22 29 22C33.9706 22 38 17.9706 38 13ZM22 13C22 9.13401 25.134 6 29 6C32.866 6 36 9.13401 36 13C36 16.866 32.866 20 29 20C25.134 20 22 16.866 22 13ZM20.0197 8.60241C20.3897 7.89229 19.9211 7.00018 19.1203 7.00017L18.9224 7.00017L4 7C2.89543 7 2 7.89543 2 9V14L19.0495 14.0011L19.0221 13.6703C19.0074 13.4488 19 13.2253 19 13C19 11.4963 19.3319 10.0702 19.9264 8.79081C19.9537 8.732 19.9851 8.66876 20.0197 8.60241ZM19.2002 15.001C20.1272 19.565 24.1624 23 29 23C30.3969 23 31.7269 22.7136 32.9345 22.1963C33.0697 22.1383 33.2421 22.0502 33.4293 21.9475C34.1236 21.5667 35.0007 22.0435 35.0006 22.8353L35.0006 23.1255L35 34C35 35.1046 34.1046 36 33 36H4C2.89543 36 2 35.1046 2 34V15L19.2002 15.001ZM4 16.999V34H33V24.314L32.815 24.3804C31.7226 24.7463 30.5724 24.9555 29.3938 24.9936L29 25C23.8815 25 19.4272 21.772 17.729 17.1287L17.683 17L4 16.999ZM17.684 9H4V12L17.042 12.001L17.057 11.8252C17.1452 10.9197 17.3349 10.0352 17.6194 9.1857L17.684 9ZM9 23C8.44772 23 8 23.4477 8 24C8 24.5523 8.44772 25 9 25H15C15.5523 25 16 24.5523 16 24C16 23.4477 15.5523 23 15 23H9ZM28.9933 9.88338C28.9355 9.38604 28.5128 9 28 9C27.4477 9 27 9.44772 27 10V14L27.0067 14.1166C27.0645 14.614 27.4872 15 28 15H32L32.1166 14.9933C32.614 14.9355 33 14.5128 33 14L32.9933 13.8834C32.9355 13.386 32.5128 13 32 13H29V10L28.9933 9.88338Z"
                              :fill="
                                socketData.status == 0 ? '#4790F6' : '#999999'
                              "
                            />
                          </svg>

                          <label
                            :style="{
                              color:
                                socketData.status == 0 ? '#4790F6' : '#999999',
                            }"
                            >{{ $t("order.timeline0") }}</label
                          >
                        </div>
                      </li>

                      <li
                        id="timeline_pending"
                        :class="{
                          active: socketData.status == 1,
                        }"
                      >
                        <div>
                          <!-- <span class="ico pending"></span> -->
                          <!-- <img src="/img/time2.svg" class="ico" alt="" /> -->
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17 2C16.4477 2 16 2.44772 16 3V9C16 9.55228 16.4477 10 17 10C17.5523 10 18 9.55228 18 9V3C18 2.44772 17.5523 2 17 2ZM10 5C9.44772 5 9 5.44772 9 6V9C9 9.55228 9.44772 10 10 10C10.5523 10 11 9.55228 11 9V6C11 5.44772 10.5523 5 10 5ZM30 12C30 11.4477 29.5523 11 29 11H5C4.44772 11 4 11.4477 4 12V18.6074C4 22.521 5.71034 27.7863 9.10906 34.4541C9.27981 34.7891 9.62402 35 10 35H24C24.376 35 24.7202 34.7891 24.8909 34.4541C25.5963 33.0703 26.2289 31.7469 26.7887 30.4834L26.8526 30.5226L27.0233 30.2567C27.9716 28.8442 29.5214 27.5275 31.6865 26.3202C35.7941 24.0297 37.7159 18.1202 35.1041 15.3182C33.7122 13.8249 31.9541 13.5171 30 14.321V12ZM30 16.5496V18.6074C30 20.6688 29.5255 23.1053 28.5796 25.9243C29.2252 25.4564 29.9363 25.0062 30.7125 24.5734C33.8 22.8517 35.2464 18.4041 33.6411 16.6818C32.7162 15.6896 31.5899 15.6075 30 16.5496ZM28 12.999V18.6074L27.9962 18.905C27.9136 22.2162 26.4666 26.7577 23.6385 32.4904L23.384 32.999H10.615L10.3615 32.4904C7.44767 26.584 6 21.942 6 18.6074V12.999H28ZM2 37C2 36.4477 2.44772 36 3 36H31C31.5523 36 32 36.4477 32 37C32 37.5523 31.5523 38 31 38H3C2.44772 38 2 37.5523 2 37ZM23 8C23 7.44772 23.4477 7 24 7C24.5523 7 25 7.44772 25 8V9C25 9.55228 24.5523 10 24 10C23.4477 10 23 9.55228 23 9V8Z"
                              :fill="
                                socketData.status == 1 ? '#4790F6' : '#999999'
                              "
                            />
                          </svg>

                          <label
                            :style="{
                              color:
                                socketData.status == 1 ? '#4790f6' : '#999999',
                            }"
                            class="foremergency"
                            >{{ $t("order.foremergency") }}</label
                          >
                          <label
                            :style="{
                              color:
                                socketData.status == 1 ? '#4790f6' : '#999999',
                            }"
                            >{{ $t("order.timeline1") }}</label
                          >
                        </div>
                      </li>
                      <li
                        id="timeline_exchange"
                        :class="{
                          active: socketData.status == 2,
                        }"
                      >
                        <div>
                          <!-- <span class="ico exchange"></span> -->
                          <!-- <img src="/img/time3.svg" class="ico" alt="" /> -->
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M35.4273 4.69044C35.2791 4.59573 35.1068 4.54541 34.9308 4.54541C34.4214 4.54541 34.0084 4.95838 34.0084 5.46781L34.008 5.99941L33 6.00004C25.3725 6.00004 19.1697 12.0998 19.0034 19.6874L19 20C19 26.5255 13.7915 31.8344 7.30497 31.9962L7 32H3C2.44772 32 2 32.4478 2 33C2 33.5129 2.38604 33.9355 2.88338 33.9933L3 34H7C14.6275 34 20.8303 27.9003 20.9966 20.3127L21 20C21 13.4746 26.2085 8.16565 32.695 8.00384L33 8.00004L34.008 7.99941L34.0084 8.6229C34.0084 8.79885 34.0588 8.97112 34.1535 9.1194C34.4277 9.54873 34.998 9.67448 35.4273 9.40027L37.8973 7.82272C38.0102 7.75061 38.106 7.65476 38.1782 7.54186C38.4524 7.11253 38.3266 6.5422 37.8973 6.26799L35.4273 4.69044ZM7 6.00004C12.026 6.00004 16.4334 8.6485 18.9028 12.6259C18.5149 13.375 18.1801 14.1622 17.904 14.9829C16.0444 10.9479 12.011 8.12123 7.30497 8.00384L7 8.00004H3C2.44772 8.00004 2 7.55232 2 7.00004C2 6.4872 2.38604 6.06453 2.88338 6.00677L3 6.00004H7ZM33 34C27.974 34 23.5666 31.3516 21.0972 27.3741C21.4851 26.6251 21.8199 25.8379 22.096 25.0172C23.9556 29.0521 27.989 31.8788 32.695 31.9962L33 32H34.0084V31.4678C34.0084 30.9584 34.4214 30.5454 34.9308 30.5454C35.1068 30.5454 35.2791 30.5957 35.4273 30.6904L37.8973 32.268C38.3266 32.5422 38.4524 33.1125 38.1782 33.5419C38.106 33.6548 38.0102 33.7506 37.8973 33.8227L35.4273 35.4003C34.998 35.6745 34.4277 35.5487 34.1535 35.1194C34.0588 34.9711 34.0084 34.7988 34.0084 34.6229V34H33Z"
                              :fill="
                                socketData.status == 2 ? '#4790f6' : '#999999'
                              "
                            />
                          </svg>

                          <label
                            :style="{
                              color:
                                socketData.status == 2 ? '#4790f6' : '#999999',
                            }"
                            >{{ $t("order.timeline2") }}</label
                          >
                        </div>
                      </li>
                      <li id="timeline_done">
                        <div>
                          <!-- <span class="ico complete"></span> -->
                          <!-- <img src="/img/time4.svg" class="ico" alt="" /> -->
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.0502 19.1213C14.4129 18.7586 14.9847 18.7327 15.3772 19.0436L15.4645 19.1213L18.9997 22.6569L26.7782 14.8786C27.1408 14.516 27.7126 14.4901 28.1052 14.8009L28.1924 14.8786C28.555 15.2413 28.5809 15.8131 28.2701 16.2056L28.1924 16.2928L19.7071 24.7781C19.3445 25.1407 18.7726 25.1666 18.3801 24.8558L18.2929 24.7781L14.0502 20.5355C13.6597 20.145 13.6597 19.5118 14.0502 19.1213Z"
                              :fill="
                                socketData.status == 3 ? '#4790f6' : '#999999'
                              "
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.84454 9.01796L18 2.00001C19.2376 1.28548 20.7624 1.28548 22 2.00001L34.1554 9.01796C35.393 9.73249 36.1554 11.053 36.1554 12.4821V26.518C36.1554 27.947 35.393 29.2675 34.1554 29.9821L22 37C20.7624 37.7145 19.2376 37.7145 18 37L5.84454 29.9821C4.60694 29.2675 3.84454 27.947 3.84454 26.518V12.4821C3.84454 11.053 4.60694 9.73249 5.84454 9.01796ZM33.1554 10.75L21 3.73206C20.3812 3.37479 19.6188 3.37479 19 3.73206L6.84454 10.75C6.22574 11.1073 5.84454 11.7675 5.84454 12.4821V26.518C5.84454 27.2325 6.22574 27.8927 6.84454 28.25L19 35.268C19.6188 35.6252 20.3812 35.6252 21 35.268L33.1554 28.25C33.7742 27.8927 34.1554 27.2325 34.1554 26.518V12.4821C34.1554 11.7675 33.7742 11.1073 33.1554 10.75Z"
                              :fill="
                                socketData.status == 3 ? '#4790f6' : '#999999'
                              "
                            />
                          </svg>

                          <label
                            :style="{
                              color:
                                socketData.status == 3 ? '#4790f6' : '#999999',
                            }"
                            class="forrefund"
                          >
                            <span>
                              {{ $t("order.timeLineRefunded") }}
                            </span>
                          </label>
                          <label
                            :style="{
                              color:
                                socketData.status == 3 ? '#4790f6' : '#999999',
                            }"
                            >{{ $t("order.timeline3") }}</label
                          >
                        </div>
                      </li>
                    </ol>
                  </div>
                </section>

                <!-- 订单信息 -->
                <section
                  class="order-action"
                  :class="{
                    new: socketData.status == 0 || socketData.status == 4,
                    exchange: socketData.status == 1,
                  }"
                  id="order_action"
                  style="
                    border: 1px solid transparent;
                    flex-direction: column;
                    flex: 1;
                  "
                >
                  <!-- 第一排 -->
                  <!-- v-if="socketData.status == 0 || socketData.status == 4" -->
                  <div class="order-action-body buttonhl my-firline">
                    <!-- 添加 状态 == 3 时显示 ******* 你的 trx 已经发送-->
                    <div
                      class="order-action-details my-img"
                      id="order_details"
                      v-if="socketData.status == 3"
                      style="
                        flex: 1 !important;
                        background: linear-gradient(180deg,#2a467e 0%,#253e74 32.15%,#1c2d5f 100%);
                      "
                    >
                      <div class="order-done-wrap">
                        <h3 class="center order-done-title">
                          {{ $t("order.doneLeft") }}
                          <div
                            :key="index"
                            v-for="(item, index) in socketData.to"
                            style="display: inline-block; margin: 0 2px"
                          >
                            {{ item[0].split("-")[0]
                            }}<sup>{{
                              item[0].split("-").length > 1
                                ? item[0].split("-")[1]
                                : ""
                            }}</sup>
                          </div>
                          {{ $t("order.doneRight") }}
                        </h3>
                        <div class="order-done-ico">
                          <span class="ico orderdone-0"
                            ><i class="ico orderdone-1"></i
                          ></span>
                        </div>
                        <div class="order-done-review">
                          <p v-if="false">
                            If you enjoy your experience on FixedFloat, please
                            leave a review at services below. We appreciate your
                            support!
                          </p>
                          <div v-if="false">
                            <a
                              class="btn hoverhl"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.bestchange.com/fixedfloat-exchanger.html"
                              ><i class="svgreviews bestchange"></i>
                              <span>Bestchange</span></a
                            >
                            <a
                              class="btn hoverhl"
                              target="_blank"
                              rel="noopener"
                              href="https://www.trustpilot.com/review/fixedfloat.com"
                              ><i class="svgreviews trustpilot"></i>
                              <span>Trustpilot</span></a
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 状态为1 或 2时 显示 交易信息 -->
                    <div
                      class="order-action-details"
                      id="order_details"
                      style="
                        background: linear-gradient(180deg,#2a467e 0%,#253e74 32.15%,#1c2d5f 100%);
                      "
                      v-else-if="
                        socketData.status == 1 || socketData.status == 2
                      "
                    >
                      <div class="order-data-details">
                        <h3>{{ $t("order.detailsH3") }}</h3>
                        <ul class="order-details-list clrfix">
                          <li class="order-txid">
                            <label>TxID:</label>
                            <div>
                              <div class="order-txid-text">
                                <span
                                  class="pseudo-hint-blue"
                                  data-hint="Copied"
                                  :title="socketData.from.txid"
                                  v-copy="socketData.from.txid"
                                >
                                  <font> {{ socketData.from.txid }} </font>
                                  <img
                                    src="/img/copy.svg"
                                    width="18"
                                    alt=""
                                    style="margin-left: 10px"
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                          <li class="view-blockchain">
                            <label>{{ $t("order.blockchain") }}:</label>
                            <div>
                              <a
                                class="link-explorer-img"
                                :class="item.icon_info"
                                rel="noreferrer noopener"
                                :href="item.location"
                                target="_blank"
                                title="Tronscan"
                                v-for="item in socketData.from.explorer"
                                :key="item.location"
                              ></a>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Received") }}:</label>
                            <div>
                              <time>{{ socketData.time.recieve }}</time>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Block") }}:</label>
                            <div>
                              <time
                                timestamp="1653557844"
                                id="order_from_tx_timeblock"
                                title="a few seconds ago (May 26, 2022 5:37 PM)"
                                >{{ socketData.from.block_time }}</time
                              >
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Confirmations") }}:</label>
                            <div>
                              <span
                                id="order_from_tx_confirmations"
                                class="tx-confirm-now"
                                :class="{
                                  accept: socketData.from.confirmations >= 5,
                                }"
                                >{{ socketData.from.confirmations }}</span
                              >
                              / <span class="tx-confirm-need">5</span>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Amount") }}:</label>
                            <div>
                              {{ socketData.from.amount_actual }}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="order-data-destination">
                        <p>
                          {{ $t("index.hourText") }}:
                          {{ socketData.deep[0] }} H, {{ $t("index.feeText") }}:
                          {{ socketData.deep[1] }}%
                        </p>
                        <hr
                          style="margin-top: 1em; opacity: 0.2; color: #3e5588"
                        />
                        <div style="margin-top: 12px">
                          <label>{{ $t("index.recvtHint") }}</label>
                          <div
                            style="margin-top: 12px; margin-left: 16px"
                            :key="index"
                            v-for="(item, index) in socketData.to"
                            class="my-receive"
                          >
                            <span
                              class="coin-img"
                              style="
                                display: inline-block;
                                float: left;
                                margin-right: 5px;
                                height: 20px;
                                width: 20px;
                              "
                              :class="item[5][0] + ' ' + item[5][1]"
                            ></span>
                            <label
                              style="
                                margin-right: 3px;
                                float: left;
                                font-weight: 600;
                                color: white;
                              "
                              >{{ item[2] }} {{ item[0].split("-")[0]
                              }}<sup
                                >{{
                                  item[0].split("-").length > 1
                                    ? item[0].split("-")[1]
                                    : ""
                                }} </sup
                              >:</label
                            ><span
                              class="order-address-destination myadd"
                              style="
                                word-break: break-all;
                                float: left;
                                font-weight: normal;
                                color: #ccc;
                                margin-left: 10px;
                              "
                              >{{ item[1] }}</span
                            >
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 状态为 0,,4 时显示 二维码等信息 -->
                    <div
                      class="order-qr-wrap my-qrcode"
                      id="order_qr"
                      v-else
                      style="
                        border: 1px solid transparent;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between !important;
                        padding-left: 20px;
                        border-radius: 10px;
                        overflow: hidden;
                        flex: 1 !important;
                      "
                    >
                      <!-- 左侧信息 -->
                      <div
                        style="
                          flex: 1;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <div
                          class="order-data-details"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: transparent;
                            /* padding-left: 32px; */
                          "
                        >
                          <!-- 左侧you send 相关信息 -->
                          <div class="order-p-wrap" style="padding-left: 10px">
                            <div
                              class="order-p-amount"
                              style="
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 16.88px;
                                color: #8c91af;
                                /* border: 1px solid red; */
                                /* color: red; */
                                /* word-wrap: break-word; */
                              "
                            >
                              <!-- {{ $t("order.amountLeft") }} -->
                              <p
                                style="
                                  font-size: 14px;
                                  font-weight: 400;
                                  text-align: left;
                                  color: #8c91af;
                                "
                              >
                                {{ $t("order.coinHeadSend") }}
                              </p>
                              <div
                                style="
                                  display: flex;
                                  align-items: center;
                                  margin-top: 12px;
                                "
                              >
                                <div
                                  class="coin-ico svgcoin"
                                  :class="socketData.from.icon_name"
                                  style="width: 22px; height: 22px"
                                ></div>
                                <b
                                  class="order-amount pseudo-hint-blue"
                                  data-hint="Copied"
                                  data-copy="0.00672647"
                                  v-copy="socketData.from.amount"
                                  :data-value="socketData.from.icon_name"
                                  style="
                                    color: #4790f6;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 19.3px;
                                    text-align: left;
                                    display: flex;
                                    align-items: center;
                                  "
                                >
                                  <span
                                    style="
                                      font-family: Bebas;
                                      font-size: 14px;
                                      font-weight: 400;
                                      line-height: 22px;
                                      text-align: left;
                                      color: #ffffff;
                                      margin-left: 10px;
                                    "
                                  >
                                    {{ socketData.from.currency.split("-")[0] }}
                                    <span
                                      style="
                                        font-family: Bebas;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 22px;
                                        text-align: left;
                                        color: #ffffff;
                                        margin-left: 10px;
                                      "
                                      >({{
                                        socketData.from.currency.split("-")
                                          .length > 1
                                          ? socketData.from.currency.split(
                                              "-"
                                            )[1]
                                          : ""
                                      }})
                                    </span>
                                  </span>

                                  <span
                                    style="
                                      font-family: Bebas;
                                      font-size: 22px;
                                      font-weight: 400;
                                      line-height: 29.11px;
                                      margin-left: 10px;
                                      color: #ffffff;
                                      margin-top: -4px;
                                    "
                                  >
                                    {{ socketData.from.amount }}
                                  </span>
                                </b>
                              </div>

                              <p
                                style="
                                  font-size: 14px;
                                  font-weight: 400;
                                  text-align: left;
                                  color: #8c91af;
                                  margin-top: 30px;
                                "
                              >
                                {{ $t("order.amountRight1") }}
                              </p>

                              <!-- {{ $t("order.amountRight") }} -->
                            </div>

                            <!-- 地址 -->
                            <div
                              class="order-p-address"
                              style="word-wrap: break-word; margin-top: 12px"
                            >
                              <div class="order-address-wrap">
                                <b
                                  style="
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 19.3px;
                                    color: #ffffff;
                                    word-wrap: break-word;
                                  "
                                  id="my-fonts"
                                  class="order-address order-address-from pseudo-hint-blue"
                                  data-hint="Copied"
                                  v-copy="socketData.from.address"
                                >
                                  <div
                                    style="
                                      display: flex;
                                      align-items: center;
                                      justify-content: center;
                                    "
                                  >
                                    <span>{{ socketData.from.address }}</span>
                                    <img
                                      src="/img/copy.svg"
                                      width="18"
                                      alt=""
                                      style="margin-left: 10px"
                                    />
                                  </div>
                                </b>
                              </div>
                            </div>
                          </div>

                          <!-- 二维码 -->
                          <div
                            class="order-qr"
                            style="
                              display: flex;
                              flex-direction: column;
                              justify-content: center;
                              /* border: 1px solid red; */
                              margin-top: 20px;
                              padding-top: 10px;
                            "
                          >
                            <input
                              type="radio"
                              id="qr_type1"
                              name="qr_switcher"
                              checked=""
                            />
                            <div
                              class="order-qrcode"
                              style="
                                width: 160px;
                                height: 160px;
                                border-radius: 10px;
                                overflow: hidden;
                                /* background-color: red !important; */
                              "
                              href="#"
                            >
                              <img :src="addressQrCode" alt="" />
                            </div>
                            <div
                              style="
                                margin-top: 10px;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 14.47px;
                                color: #8c91af;
                              "
                              data-count="1"
                            >
                              <span>{{ $t("order.qrcode") }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <hr style="margin: 0; width: calc(100% - 20px);margin-bottom: 20px;opacity: 0.2"> -->
                      <p
                        style="
                          font-size: 16px;
                          font-weight: 400;
                          line-height: 16.88px;
                          text-align: center;
                          margin-top: -10px;
                          padding-right: 20px;
                          width: calc(100% - 40px) !important;
                          margin-bottom: 15px;
                          font-size: 14px;
                          border-top: 2px solid rgba(62, 85, 136, 1);
                          padding-top: 15px;
                        "
                        class="order-attention"
                        v-html="$t('order.attention', { number: 5 })"
                      ></p>
                    </div>

                    <!-- 左侧订单 倒计时信息 -->
                    <section
                      class="order-head-info"
                      id="order_info"
                      style="
                        width: 260px;
                        padding: 0;
                        border-radius: 10px;
                        /* background-color: #4775f6; */
                        overflow: hidden;
                      "
                    >
                      <div class="order-head-info-inner" id="order_info_inner">
                        <div
                          class="order-time"
                          :class="
                            socketData.status == 0
                              ? timeLeft == -1
                                ? 'expired'
                                : 'new'
                              : socketData.status == 1 || socketData.status == 2
                              ? 'exchange'
                              : socketData.status == 4
                              ? 'expired'
                              : 'done'
                          "
                          style="border-radius: 10px"
                        >
                          <label
                            :data-remaining="$t('order.orderInfoRemaining')"
                            :data-status="$t('order.orderInfoStatus')"
                            style="color: white; opacity: 0.7; font-size: 14px"
                          ></label>
                          <div>
                            <span
                              id="order_time "
                              class="hl"
                              :data-time="getTime(timeLeft)"
                              :data-expired="$t('order.expired')"
                              :data-exchange="$t('order.exchange')"
                              :data-done="$t('order.done')"
                              data-emergency="User response"
                              data-refund="Refunded"
                              style="font-size: 18px; margin-top: -15px"
                            ></span>
                          </div>
                        </div>
                        <div class="order-id-wrap">
                          <label>{{ $t("order.orderInfoId") }}</label>
                          <div style="margin-top: 2px; margin-bottom: 4px">
                            <span
                              class="pseudo-hint-blue"
                              :data-copy="orderId"
                              data-hint="Copied"
                              v-copy="orderId"
                              ><span class="order-id hl">{{ orderId }}</span>
                              <img
                                src="/img/copy.svg"
                                width="18"
                                alt=""
                                style="margin-left: 10px"
                              />
                            </span>
                          </div>
                        </div>

                        <div class="order-type-me">
                          <label>{{ $t("order.orderInfoType") }}</label>
                          <div style="margin-top: 2px; margin-bottom: 4px">
                            <span class="order-type"
                              >{{ socketData.type }} rate</span
                            >
                          </div>
                        </div>

                        <div
                          :class="{
                            'order-type-me': !(
                              socketData.status == 4 || socketData.status < 1
                            ),
                          }"
                        >
                          <label>{{ $t("order.orderInfoCreation") }}</label>
                          <div style="margin-top: 2px; margin-bottom: 4px">
                            <time
                              id="order_time_creation"
                              :timestamp="socketData.time.create_ts"
                              style="font-size: 14px"
                              >{{ socketData.time.create }}</time
                            >
                          </div>
                        </div>
                        <div
                          :class="{
                            none:
                              socketData.status == 4 || socketData.status < 1,
                            'order-type-me': !(
                              socketData.status == 4 || socketData.status < 3
                            ),
                          }"
                        >
                          <label>{{ $t("order.orderInfoReceived") }}</label>
                          <div style="margin-top: 2px; margin-bottom: 4px">
                            <time
                              id="order_time_tx"
                              timestamp=""
                              style="font-size: 14px"
                              >{{ socketData.time.recieve }}</time
                            >
                          </div>
                        </div>
                        <div
                          :class="{
                            none:
                              socketData.status == 4 || socketData.status < 3,
                          }"
                        >
                          <label>{{ $t("order.orderInfoCompleted") }}</label>
                          <div style="margin-top: 2px; margin-bottom: 4px">
                            <time
                              id="order_time_competed"
                              timestamp=""
                              style="font-size: 14px"
                              >{{ socketData.time.complete }}</time
                            >
                          </div>
                        </div>
                      </div>
                    </section>

                    <!-- 订单信息 || start -->

                    <!-- 订单信息 || end -->
                  </div>

                  <!-- 第二排订单 -->
                  <div style="border: 1px solid transparent">
                    <div
                      class="order-action-details"
                      id="order_details"
                      v-if="socketData.status == 0 || socketData.status == 4"
                      style="
                        background: linear-gradient(180deg,#2a467e 0%,#253e74 32.15%,#1c2d5f 100%);
                        width: 100%;
                      "
                    >
                      <div class="order-data-destination">
                        <p style="padding-top: 0">
                          {{ $t("index.hourText") }}:
                          {{ socketData.deep[0] }} H, {{ $t("index.feeText") }}:
                          {{ socketData.deep[1] }}%
                        </p>
                        <hr
                          style="margin-top: 10px; opacity: 0.2; color: #3e5588"
                        />
                        <div style="margin-top: 15px">
                          <label>{{ $t("index.recvtHint") }}</label>
                          <div
                            :key="index"
                            style="margin-top: 14px; margin-left: 18px"
                            v-for="(item, index) in socketData.to"
                            class="my-receive"
                          >
                            <span
                              class="coin-img"
                              style="
                                display: inline-block;
                                float: left;
                                margin-right: 5px;
                                height: 20px;
                                width: 20px;
                              "
                              :class="item[5][0] + ' ' + item[5][1]"
                            >
                            </span>
                            <label
                              style="
                                margin-right: 3px;
                                float: left;
                                color: #fff;
                                font-weight: 600;
                              "
                              >{{ item[2] }} {{ item[0].split("-")[0]
                              }}<sup
                                >{{
                                  item[0].split("-").length > 1
                                    ? item[0].split("-")[1]
                                    : ""
                                }} </sup
                              >:
                            </label>

                            <span
                              class="order-address-destination myadd"
                              style="
                                word-break: break-all;
                                color: #8c91af;
                                float: left;
                                margin-left: 10px;
                                font-weight: normal;
                              "
                              >{{ item[1] }}</span>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 状态为1 或者 为 2 时显示 这个交易信息 -->
                    <!-- <div
                      class="order-action-details"
                      id="order_details"
                      style="background: linear-gradient(180deg, #2a467e 0%, #253e74 32.15%, #1c2d5f 100%);"
                      v-else-if="
                        socketData.status == 1 || socketData.status == 2
                      "
        
                    >
                      <div class="order-data-details">
                        <h3>{{ $t("order.detailsH3") }}</h3>
                        <ul class="order-details-list clrfix">
                          <li class="order-txid">
                            <label>TxID:</label>
                            <div>
                              <div class="order-txid-text">
                                <span
                                  class="pseudo-hint-blue"
                                  data-hint="Copied"
                                  :title="socketData.from.txid"
                                  v-copy="socketData.from.txid"
                                >
                                  <font> {{ socketData.from.txid }} </font>
                                  <img
                                    src="/img/copy.svg"
                                    width="18"
                                    alt=""
                                    style="margin-left: 10px"
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                          <li class="view-blockchain">
                            <label>{{ $t("order.blockchain") }}:</label>
                            <div>
                              <a
                                class="link-explorer-img"
                                :class="item.icon_info"
                                rel="noreferrer noopener"
                                :href="item.location"
                                target="_blank"
                                title="Tronscan"
                                v-for="item in socketData.from.explorer"
                                :key="item.location"
                              ></a>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Received") }}:</label>
                            <div>
                              <time>{{ socketData.time.recieve }}</time>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Block") }}:</label>
                            <div>
                              <time
                                timestamp="1653557844"
                                id="order_from_tx_timeblock"
                                title="a few seconds ago (May 26, 2022 5:37 PM)"
                                >{{ socketData.from.block_time }}</time
                              >
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Confirmations") }}:</label>
                            <div>
                              <span
                                id="order_from_tx_confirmations"
                                class="tx-confirm-now"
                                :class="{
                                  accept: socketData.from.confirmations >= 5,
                                }"
                                >{{ socketData.from.confirmations }}</span
                              >
                              / <span class="tx-confirm-need">5</span>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Amount") }}:</label>
                            <div>
                              {{ socketData.from.amount_actual }}
                            </div>
                          </li>
                      
                        </ul>
                      </div>
                      <div class="order-data-destination">
                        <p>
                          {{ $t("index.hourText") }}:
                          {{ socketData.deep[0] }} H, {{ $t("index.feeText") }}:
                          {{ socketData.deep[1] }}%
                        </p>
                        <hr style="margin-top: 1em;opacity: 0.2;color:#3E5588" />
                        <div style="margin-top: 12px;">
                          <label >{{ $t("index.recvtHint") }}</label>
                          <div
                            style="margin-top: 12px;margin-left: 16px;"
                            :key="index"
                            v-for="(item, index) in socketData.to"
                          >
                            <span
                              class="coin-img"
                              style="
                                display: inline-block;
                                float: left;
                                margin-right: 5px;
                                height: 20px;
                                width: 20px;
                               
                              "
                              :class="item[5][0] + ' ' + item[5][1]"
                            ></span>
                            <label style="margin-right: 3px; float: left; font-weight: 600;
                                color:white;"
                              >{{ item[2] }} {{ item[0].split("-")[0]
                              }}<sup
                                >{{
                                  item[0].split("-").length > 1
                                    ? item[0].split("-")[1]
                                    : ""
                                }} </sup
                              >:</label
                            ><span
                              class="order-address-destination"
                              style="word-break: break-all; float: left;
                              font-weight: normal;color: #ccc;margin-left: 10px;"
                              >{{ item[1] }}</span
                            >
                            <br />
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- 图片 你的trx已经发送 -->
                    <!-- <div
                      class="order-action-details"
                      id="order_details"
                      v-else-if="socketData.status == 3"
                    >
                      <div class="order-done-wrap">
                        <h3 class="center order-done-title">
                          {{ $t("order.doneLeft") }}
                          <div
                            :key="index"
                            v-for="(item, index) in socketData.to"
                            style="display: inline-block; margin: 0 2px"
                          >
                            {{ item[0].split("-")[0]
                            }}<sup>{{
                              item[0].split("-").length > 1
                                ? item[0].split("-")[1]
                                : ""
                            }}</sup>
                          </div>
                          {{ $t("order.doneRight") }}
                        </h3>
                        <div class="order-done-ico">
                          <span class="ico orderdone-0"
                            ><i class="ico orderdone-1"></i
                          ></span>
                        </div>
                        <div class="order-done-review">
                          <p v-if="false">
                            If you enjoy your experience on FixedFloat, please
                            leave a review at services below. We appreciate your
                            support!
                          </p>
                          <div v-if="false">
                            <a
                              class="btn hoverhl"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.bestchange.com/fixedfloat-exchanger.html"
                              ><i class="svgreviews bestchange"></i>
                              <span>Bestchange</span></a
                            >
                            <a
                              class="btn hoverhl"
                              target="_blank"
                              rel="noopener"
                              href="https://www.trustpilot.com/review/fixedfloat.com"
                              ><i class="svgreviews trustpilot"></i>
                              <span>Trustpilot</span></a
                            >
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>

                  <!-- status == 3 时显示状态 -->
                  <div
                    class="order-action-details more"
                    id="order_details_more"
                    v-if="socketData.status == 3"
                    style="
                      background: linear-gradient(180deg,#2a467e 0%,#253e74 32.15%,#1c2d5f 100%);
                    "
                  >
                    <div class="order-details-more-txs">
                      <div class="order-details-tx">
                        <h3>{{ $t("order.doneDetailsright") }}</h3>
                        <ul
                          class="order-details-list clrfix"
                          :key="index"
                          v-for="(xitem, index) in socketData.to"
                        >
                          <li class="view-blockchain">
                            <span
                              class="coin-img"
                              style="
                                display: inline-block;
                                float: left;
                                margin-right: 5px;
                                height: 20px;
                                width: 20px;
                              "
                              :class="xitem[5][0] + ' ' + xitem[5][1]"
                            ></span>
                            <label style="width: auto"
                              ><font color="white"
                                >{{ xitem[6] }} {{ xitem[0] }}</font
                              >
                              | {{ $t("order.blockchain") }}:</label
                            >
                            <div>
                              <a
                                class="link-explorer-img"
                                :class="item.icon_info"
                                rel="noreferrer noopener"
                                :href="item.location"
                                target="_blank"
                                title="Tronscan"
                                v-for="item in xitem[8]"
                                :key="item.location"
                              ></a>
                            </div>
                          </li>
                          <li class="order-txid">
                            <label>Address:</label>
                            <div>
                              <span
                                class="pseudo-hint-blue"
                                data-hint="Copied"
                                v-copy="xitem[1]"
                              >
                                <font> {{ xitem[1] }} </font>
                                <img
                                  src="/img/copy.svg"
                                  width="18"
                                  alt=""
                                  style="margin-left: 10px"
                                />
                              </span>
                            </div>
                          </li>
                          <li class="order-txid">
                            <label>TxID:</label>
                            <div>
                              <span
                                class="pseudo-hint-blue"
                                data-hint="Copied"
                                v-copy="xitem[7]"
                              >
                                <font> {{ xitem[7] }} </font>
                                <img
                                  src="/img/copy.svg"
                                  width="18"
                                  alt=""
                                  style="margin-left: 10px"
                                />
                              </span>
                            </div>
                          </li>

                          <hr
                            style="
                              margin-bottom: 5px;
                              opacity: 0.2;
                              color: #3e5588;
                            "
                          />
                        </ul>
                      </div>
                      <div class="order-details-tx">
                        <h3>{{ $t("order.doneDetailsLeft") }}</h3>
                        <ul class="order-details-list clrfix">
                          <li class="order-txid">
                            <label>TxID:</label>
                            <div>
                              <span
                                class="pseudo-hint-blue"
                                data-hint="Copied"
                                v-copy="socketData.from.txid"
                              >
                                <font> {{ socketData.from.txid }} </font>
                                <img
                                  src="/img/copy.svg"
                                  width="18"
                                  alt=""
                                  style="margin-left: 10px"
                                />
                              </span>
                            </div>
                          </li>
                          <li class="view-blockchain">
                            <label>{{ $t("order.blockchain") }}:</label>
                            <div>
                              <a
                                class="link-explorer-img"
                                :class="item.icon_info"
                                rel="noreferrer noopener"
                                :href="item.location"
                                target="_blank"
                                title="Tronscan"
                                v-for="item in socketData.from.explorer"
                                :key="item.location"
                              ></a>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Received") }}:</label>
                            <div>
                              <time timestamp="1653557852">{{
                                socketData.time.recieve
                              }}</time>
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Block") }}:</label>
                            <div>
                              <time
                                timestamp="1653557844"
                                id="order_from_tx_timeblock"
                                >{{ socketData.from.block_time }}</time
                              >
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Confirmations") }}:</label>
                            <div id="order_from_tx_confirmations">
                              {{ socketData.from.confirmations }}
                            </div>
                          </li>
                          <li>
                            <label>{{ $t("order.Amount") }}:</label>
                            <div>{{ socketData.from.amount_actual }}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- <section
                  class="order-emergency clrfix"
                  id="section_emergency"
                ></section> -->

                <!-- what do you know -->
                <section
                  class="order-info"
                  style="
                    width: 100%;
                    justify-content: space-between;
                    margin-top: 16px;
                  "
                >
                  <section
                    class="order-info-known"
                    style="border-radius: 10px; padding: 22px"
                  >
                    <h3
                      style="
                        font-family: Bebas;
                        word-spacing: 8px;
                        font-weight: 400;
                        line-height: 21.17px;
                        color: #ffffff;
                      "
                    >
                      {{ $t("order.knownTitle") }}
                    </h3>

                    <div class="order-note">
                      <img src="/img/know1.png" width="22px" alt="" />
                      <span
                        style="
                          display: inline-block;
                          color: #8c91af;
                          /* ; */
                          font-size: 14px;
                          font-weight: 400;
                        "
                        v-html="$t('order.known1', { number: 5 })"
                      ></span>
                    </div>

                    <div class="order-note">
                      <img src="/img/know2.png" width="22px" alt="" />
                      <span
                        style="
                          color: #8c91af;
                          /* ; */
                          font-size: 14px;
                          font-weight: 400;
                        "
                        >{{ $t("order.known4") }}</span
                      >
                    </div>
                    <div class="order-note">
                      <img src="/img/know3.png" width="22px" alt="" />
                      <span
                        style="
                          color: #8c91af;
                          /* ; */
                          font-size: 14px;
                          font-weight: 400;
                        "
                      >
                        {{ $t("order.known5") }}
                      </span>
                    </div>
                  </section>

                  <!-- Order status notifications -->
                  <section
                    style="width: 300px; border-radius: 10px"
                    class="order-notyfy clrfix buttonhl"
                  >
                    <div id="notyfy_tosubscribe" class="active">
                      <h3
                        style="
                          color: #ffffff;
                          font-family: Bebas;
                          text-align: center;
                          word-spacing: 8px;
                        "
                      >
                        {{ $t("order.notyfyTitle") }}
                      </h3>
                      <p
                        style="
                          /* ; */
                          font-size: 12px;
                          font-weight: 400;
                          line-height: 14.47px;
                          text-align: justified;
                          color: #8c91af;
                          margin-top: 10px;
                          font-size: 13px;
                        "
                      >
                        {{ $t("order.notyfyContent") }}
                      </p>

                      <!-- 邮件地址 -->
                      <div
                        class="order-notyfy-input-wrap"
                        :data-value="socketData.from.icon_name"
                        style="background-color: transparent !important"
                      >
                        <input
                          id="notyfy_email_input"
                          required=""
                          type="text"
                          :placeholder="$t('order.notyfyPlaceholder')"
                          class="borderhl"
                          v-model="emailAddress"
                          :style="{
                            borderColor: colorList[socketData.from.icon_name],
                          }"
                          style="
                            background-color: #0000001f;
                            border-style: none;
                            border-radius: 5px;
                            height: 44px;
                            margin-top: 20px;

                            text-align: left;
                            color: #7992c1;
                          "
                        />
                        <button
                          id="notyfy_submit"
                          class="btn bghl"
                          @click.prevent="submitEmail"
                          :class="{
                            loading: isSubmiting,
                          }"
                          style="
                            background: linear-gradient(
                              270deg,
                              #4775f6 0%,
                              #2957d0 100%
                            );
                            border: 0.5px solid #576cf2;
                            box-shadow: 0px 4px 12px 0px #1d72e767;
                            height: 48px;
                            margin-top: 12px;
                            font-family: Bebas;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18.52px;
                            box-shadow: 0px 4px 12px 0px #1d72e767;
                            border-radius: 10px;
                          "
                        >
                          {{ $t("order.notyfybtn") }}</button
                        ><i id="notyfy_email_error"></i>
                      </div>
                    </div>
                    <div id="notyfy_subscribed">
                      <h3>Order status notifications</h3>
                      <p>
                        You have subscribed to notifications of changes to this
                        order; a notification has been sent to your email
                        <b id="notyfy_email"></b>
                      </p>
                      <a
                        href=""
                        class="order-notyfy-wrong hoverhl"
                        id="notyfy_wrong"
                        >I want to change my email for receiving
                        notifications</a
                      >
                      <span class="ico notyfy-compl-0"
                        ><i class="ico hl notyfy-compl-1"></i
                      ></span>
                    </div>
                  </section>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
import Iheader from "@/components/header.vue";
import colorList from "@/plugins/data/colors";
import { color } from "echarts";
import QRCode from "qrcode";
export default {
  name: "order",
  components: {
    Iheader,
  },
  data() {
    return {
      path: "wss://x-api.iixqq.com/wsapp/",
      socket: "",
      orderId: "",
      socketData: {
        from: {
          address: "",
          amount: "",
          currency: "",
        },
        to: [],
        time: {
          create: "",
          create_ts: 0,
          left: 0,
        },
        type: "float",
        status: 0,
        deep: [0, 0],
      },
      addressQrCode: "",
      timeLeft: 0,
      timeInterval: "",
      timeout: 28 * 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      emailAddress: "",
      isSubmiting: false,
      colorList,
    };
  },
  mounted() {
    // 初始化
    let { orderId } = this.$route.query;
    this.orderId = orderId;
    this.initWebSocket();
  },
  watch: {
    "socketData.from.address"(newVal) {
      if (newVal) {
        let that = this;
        QRCode.toDataURL(newVal, function (err, url) {
          that.addressQrCode = url;
        });
      }
    },
  },
  methods: {
    initWebSocket() {
      this.socket = new WebSocket(this.path);
      // 连接错误
      this.socket.onerror = this.error;

      // 连接成功
      this.socket.onopen = this.open;

      // 收到消息的回调
      this.socket.onmessage = this.getMessage;
    },
    reconnect() {
      // 重新连接
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      this.timeoutnum && clearTimeout(this.timeoutnum);
      this.timeoutnum = setTimeout(() => {
        //新连接
        this.initWebSocket();
        this.lockReconnect = false;
      }, 5000);
    },
    reset() {
      // 重置心跳
      // 清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      // 重启心跳
      this.start();
    },
    start() {
      // 开启心跳
      this.timeoutObj && clearTimeout(this.timeoutObj);
      this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
      this.timeoutObj = setTimeout(() => {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (this.socket && this.socket.readyState == 1) {
          // 如果连接正常
          this.socket.send("ping");
        } else {
          // 否则重连
          this.reconnect();
        }
        this.serverTimeoutObj = setTimeout(() => {
          //超时关闭
          this.socket.close();
        }, this.timeout);
      }, this.timeout);
    },
    open() {
      //开启心跳
      this.start();
      if (this.orderId) {
        this.send();
      }
    },
    error() {
      this.reconnect();
      // this.$Message.error("Error! Please try again.");
    },
    getMessage(msg) {
      if (msg.data == "pong") {
        this.reset();
        return;
      }
      try {
        let messageData = JSON.parse(msg.data);
        if (messageData.event == "subscribed") {
          this.socketData = messageData.result;
          if (this.socketData.from.icon_info) {
            this.socketData.from.icon_name = this.socketData.from.icon_info[1];
          }

          if (this.socketData.to.icon_info) {
            this.socketData.to.icon_name = this.socketData.to.icon_info[1];
          }

          if (this.socketData.error) {
            this.$Message.error(this.socketData.error);
            this.$router.go(-1);
          }
          if (this.socketData.time.left) {
            this.timeLeft = this.socketData.time.left;
            clearInterval(this.timeInterval);
            this.timeInterval = setInterval(() => {
              if (this.timeLeft > 0) {
                this.timeLeft--;
              } else {
                clearInterval(this.timeInterval);
                this.timeLeft--;
              }
            }, 1000 * 1);
          }
        } else if (messageData.event == "order_change") {
          let result = messageData.result;
          for (let key in result) {
            this.socketData[key] =
              typeof this.socketData[key] == "object"
                ? {
                    ...this.socketData[key],
                    ...result[key],
                  }
                : result[key];
          }
        }
        // 测试订单数据 测试删除 ***********
        // console.log(this.socketData,'12312313123133')
        // this.socketData.status = 2;

        // ***********************
      } catch (error) {
        console.log("error", error);
        // this.reconnect();
      }
    },
    send() {
      this.socket.send(
        JSON.stringify({
          method: "subscribe",
          params: {
            channel: "order_changes",
            id: this.orderId,
          },
        })
      );
    },
    getTime(time) {
      // 转换为式分秒
      let h = parseInt((time / 60 / 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(time % 60);
      s = s < 10 ? "0" + s : s;
      // 作为返回值返回
      return `${m}:${s}`;
    },
    submitEmail() {
      if (this.isSubmiting) {
        return;
      }
      let RE = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!this.emailAddress || !RE.test(this.emailAddress)) {
        this.$Message.warning("error email address!");
        return;
      }
      this.isSubmiting = true;
      this.$api
        .getExchangeAddressInfo({
          id: this.orderId,
          email: this.emailAddress,
        })
        .then((res) => {
          this.isSubmiting = false;
          this.$Message.success("SUCCESS");
        })
        .catch(() => {
          this.isSubmiting = false;
        });
    },
  },
  destroyed() {
    // 销毁监听
    this.socket.close();
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/css/order.css";
a,
.hl,
.hoverhl:hover,
.linkhl a:hover {
  color: white;
}

#notyfy_email_input {
  &::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: #7992c1;

    font-size: 14px;
    font-weight: 400;
    line-height: 16.88px;
    text-align: left;
  }
}

.order-data-destination {
  border-style: none;
}

.order-head-info-inner > div {
  padding: 6px 0;
  border-style: none;
}

.order-id-wrap {
  &::before {
    content: "";
    // color: red;
    // float: left;
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 90%;
    // height: 1px;
    // background-color: #3E5588;
    translate: -50%;
    border: 1px solid #3e5588;
    // transform: scaleY(0.5);
  }
}

.order-type-me {
  &::before {
    content: "";
    // color: red;
    // float: left;
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 90%;
    // height: 1px;
    // background-color: #8c91af;
    border: 1px solid #3e5588;
    translate: -50%;
    // transform: scaleY(0.5);
  }
}

#order_info_inner > div {
  position: relative;
  label {
    // color: red;
    // ;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.47px;
    text-align: center;
    color: #8c91af;
  }
  & > div {
    // margin-top: 12px;
    span {
      // color: red;
      // ;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.88px;
      text-align: left;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 900px) {
  .my-firline {
    .my-qrcode {
      padding: 10px;
      .order-data-details {
        // border: 1px solid red;
        padding: 10px 0 !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .order-action-body.buttonhl.my-firline {
    padding: 0;
  }

  .my-receive {
    // border: 1px solid red;
    // height: 40px;
    margin-left: 0 !important;
    margin-top: 15px !important;
    display: flex;
    flex-wrap: wrap;
    .myadd{
      margin-bottom: 0 !important;
      margin-left: 20px !important;
    }
  }
  .my-img {
    margin-top: 10px;
  }

  .timeline-container {
    margin-top: 80px !important;
  }

  #notyfy_submit {
    height: 44px !important;
  }
  .my-firline {
    display: flex;
    flex-direction: column;
    align-items: center;

    // 时间
    .order-head-info {
      width: 100% !important;
      margin: 0 auto;
    }
    padding: 0 !important;

    // 二维码
    .my-qrcode {
      margin-top: 10px;
      // width: calc(100% - 20px);
      // background-color: red !important;
      // padding: 0;
      padding-left: 0px !important;
      padding: 10px;
      .order-data-details {
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: flex-start;
        padding-left: 0px !important;
      }
      .order-attention {
        width: 100% !important;
        margin: 0 auto;
        flex: 1;
        // border: 1px solid red;
      }
    }
    .order-action-details {
      margin-top: 10px;
    }

    .order-attention {
      margin-top: 10px !important;
    }
  }

  .order-action-details {
    // border:1px solid red;
    padding: 16px;
    .order-address-destination {
      margin-top: 5px !important;
      // border: 1px solid red;
      margin-bottom: 15px;
    }
  }

  .order-data-destination {
    > div > div {
      margin-top: 10px;
    }
  }

  .order-notyfy.clrfix.buttonhl {
    width: 100% !important;
  }
  .order-address-wrap {
    color: red !important;
  }

  #my-fonts {
    font-size: 13px !important;
  }
}

.order-p-wrap:first-of-type {
  margin-bottom: 1.2em;
}

.order-action-details {
  border-radius: 10px;
}

.order-note {
  display: flex;
  align-items: center;
  // border: 1px solid red;
  position: relative;
  > img {
    position: absolute;
    top: 0;
  }
  > span {
    margin-left: 30px;
  }
}
</style>